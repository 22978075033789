var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":"","tile":""}},[_c('v-container',_vm._l((_vm.loading ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10] : _vm.items),function(movie){return _c('v-list',{key:movie.id,staticClass:"pa-0"},[_c('v-lazy',{key:movie.id || movie.item_id,attrs:{"min-height":"152"}},[_c('v-skeleton-loader',{attrs:{"tile":"","loading":_vm.loading,"type":"list-item-avatar-three-line"}},[_c('router-link',{staticClass:"pa-0 transparent",attrs:{"tag":"div","flat":"","to":_vm.getTo(movie)}},[_c('v-container',{attrs:{"data-v-step":"browse_1"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"shrink pa-0"},[_c('v-container',{staticClass:"pa-0 py-1",attrs:{"fill-height":""}},[_c('v-row',{attrs:{"no-gutters":""}},[(movie.cover || movie.image)?_c('movie-img',{attrs:{"path":movie.cover || movie.image,"blur":movie.protected || movie.item_protected === 'true'}}):_c('v-icon',{attrs:{"size":"80"}},[_vm._v("mdi-filmstrip")])],1)],1)],1),_c('v-col',{staticClass:"grow"},[_c('v-list',{staticClass:"pa-0 transparent",attrs:{"three-line":"","width":"100%"}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"line-clamp line-clamp-1",staticStyle:{"white-space":"initial","-webkit-line-clamp":"1","-webkit-box-orient":"vertical","display":"-webkit-box"},domProps:{"innerHTML":_vm._s(("" + (movie.title || movie.name)))}}),(
                            ((movie.resume_time / 60) * 100) /
                              movie.duration >
                            0
                          )?_c('v-progress-linear',{attrs:{"height":"3","value":((movie.resume_time / 60) * 100) / movie.duration,"color":((movie.resume_time / 60) * 100) /
                              movie.duration >
                            0
                              ? 'primary'
                              : 'transparent'}}):_vm._e(),_c('v-list-item-subtitle',[_c('span',[_vm._v(_vm._s(movie.description))])])],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }